<template>
  <el-form class="form" ref="formData" :model="formData" :rules="rules" size="medium" label-width="100px" :disabled="isView">
    <el-row>
      <el-col :span="24">
        <el-form-item label="学校名称" label-width="100px" prop="Name">
          <el-input v-if="isView||isEdit" v-model="formData.SchoolName" readonly disabled />
          <el-autocomplete v-else v-model="formData.Name" :trigger-on-focus="false" :fetch-suggestions="searchSchool" placeholder="请输入学校名称" clearable style="width:100%" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="联系人" prop="SchoolContacts">
          <el-input v-model="formData.SchoolContacts" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="联系方式" prop="SchoolPhone">
          <el-input v-model="formData.SchoolPhone" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="!isView">
      <el-col :span="24">
        <el-form-item size="large">
          <el-button type="primary" v-if="!isView" @click="submitForm" :loading="submitting">确认</el-button>
          <el-button @click="cancle" :loading="submitting">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex';
import { getInfo, insert, edit } from '@/api/schoolenterprise';
import { searchSchoolBaseList } from '@/api/schoolInfo'
import { CooperationTypeEnum, StaffTypeEnum } from '@/utils/constant';
import { isPhone } from '@/utils/validate';
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    isView: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        Id: this.$route.query.id || undefined,
        Type: CooperationTypeEnum.EnterpriseToSchool,
        Name: undefined,
	
        SchoolName: undefined,
        EnterpriseId: undefined,
        SchoolId: undefined,
        SchoolPhone: undefined,
        SchoolContacts: undefined
      },
      rules: {
        Name: [
          {
            required: true,
            message: '请输入学校名称',
            trigger: 'blur',
          }
        ],
        SchoolPhone: [
          {
            required: true, message: "请输入手机号",
            validator: (_rule, value, callback) => {
              if (!isPhone(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
          }
        ]
      },
      submitting: false,
      loaded: false
    }
  },
  computed: {
    ...mapGetters(['employee'])
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      if ((this.isView || this.isEdit) && this.formData.Id) {
        const { Result } = await getInfo(this.formData.Id);
        this.formData = Object.assign({}, this.formData, Result, { Name: Result.SchoolName });
        this.loaded = true;
      } else {
        const { Employer: { Id } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
        this.formData.EnterpriseId = Id;
        this.loaded = true;
      }
      this.$nextTick(_=>{
        this.$refs['formData'].clearValidate();
      });
    },
    async searchSchool(query, cb) {
      if (query) {
        const { Result } = await searchSchoolBaseList(query);
        cb(Result.map(({ Text }) => ({
          value: Text,
        })));
      } else {
        cb([])
      }
    },
    submitForm() {
      if (!this.formData.EnterpriseId) {
        this.$message.error("新增失败，请稍后重试");
        return;
      }
      this.$refs['formData'].validate(msg => {
        if (!msg) {
          return;
        }
        this.submitting = true;
        const createData = Object.assign({}, this.formData);
        const editData = Object.assign({}, this.formData, {
          Phone: this.formData.SchoolPhone,
          Contacts: this.formData.SchoolContacts,
          //todo:更新的时候只更新对方的联系方式，所以需要将类型转换
          Type: CooperationTypeEnum.EnterpriseToSchool
        });
        const task = this.isEdit ? edit(editData) : insert(createData);
        task.then(_ => {
          this.submitting = false;
          this.$router.push('/enterprise/school');
        }).catch(_ => {
          this.submitting = false;
        });
      });
    },
    deallogo(fieldName, value) {
      this.formData[fieldName] = value;
    },
    dealDescription(fieldName, value) {
      this.formData[fieldName] = value;
    },
    cancle() {
      if (!this.isView) {
        this.$confirm('系统将不会保存您所更改的内容！', '您确定要离开当前页面吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: '温馨提示'
          })
          .then(() => {
            this.$router.go(-1)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$router.go(-1)
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.form {
  background: #fff;
  padding: 48px;
  margin-bottom: 20px;
  border-radius: 8px;
  .to {
    ::v-deep label {
      &::before {
        display: none;
      }
    }
  }
}
</style>>